var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": _vm.$t("cip.dc.DTRecord.title") },
      }),
      _c(
        "div",
        { staticClass: "headers", staticStyle: { display: "flex" } },
        [
          _c("el-input", {
            staticClass: "hinput",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$t("cip.dc.DTManagement.field.TaskName"),
              size: "mini",
            },
            model: {
              value: _vm.listParms.taskName,
              callback: function ($$v) {
                _vm.$set(_vm.listParms, "taskName", $$v)
              },
              expression: "listParms.taskName",
            },
          }),
          _c(
            "el-button-group",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-search",
                },
                on: { click: _vm.onLoad },
              }),
              _c("el-button", {
                attrs: { size: "mini", icon: "reset-refresh icon-refresh" },
                on: { click: _vm.czFn },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          "data-total": _vm.total,
          page: _vm.page,
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          gridRowBtn: _vm.gridRowBtn,
          searchColumns: _vm.searchColumns,
        },
        on: {
          "page-size-change": _vm.handleSizeChange,
          "page-current-change": _vm.handleCurrentChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.handleSelectionChange,
          "grid-view": _vm.toSee,
          "grid-romve": _vm.toDel,
        },
        scopedSlots: _vm._u([
          {
            key: "triggerType",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(row.triggerType == 1 ? "手动" : "判定方法") +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "judgeName",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.ToJudge(row)
                      },
                    },
                  },
                  [_vm._v(_vm._s(row.judgeName))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("cip.dc.DComission.tip"),
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.dialogtext))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.dc.DComission.false")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.SureToExecute },
                },
                [_vm._v(_vm._s(_vm.$t("cip.dc.DComission.sure")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }