<template>
  <div>
    <head-layout :head-title="$t('cip.dc.DTRecord.title')" >
    </head-layout>
    <div class="headers" style="display: flex">
      <el-input
       :placeholder="$t('cip.dc.DTManagement.field.TaskName')"
        v-model="listParms.taskName"
        class="hinput"
        size="mini"
        style="width: 200px"
      />
      <el-button-group style="margin-left: 10px">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="onLoad"
        ></el-button>
        <el-button
          size="mini"
          icon="reset-refresh icon-refresh"
          @click="czFn"
        ></el-button>
      </el-button-group>
    </div>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      @grid-view="toSee"
      @grid-romve="toDel"
      :searchColumns="searchColumns"
    >
      <template slot="triggerType" slot-scope="{ row }">
        {{ row.triggerType == 1 ? "手动" : "判定方法" }}
      </template>
      <template slot="judgeName" slot-scope="{ row }">

         <el-button type="text" @click="ToJudge(row)">{{ row.judgeName }}</el-button>
      </template>
    </grid-layout>

    <el-dialog
      :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{dialogtext}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button type="primary" @click="SureToExecute">{{ $t('cip.dc.DComission.sure') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkTaskRecordList,DelTaskRecordDetail} from "@/api/dataAcquisition/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  props: {},
  data() {
    return {
      total:'',
      dialogtext:'',
      btnType:'',
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      listParms: {
        pageNum: 1,
        pageSize: 10,
        taskName: "",
      },
      gridRowBtn: [
        // {
        //   label: this.$t("cip.cmn.btn.editBtn"),
        //   emit: "grid-edit",
        //   remark: "edit",
        //   type: "text",
        //   icon: "",
        // },

        // {
        //   label: this.$t("cip.cmn.btn.viewBtn"),
        //   emit: "grid-view",
        //   remark: "view",
        //   type: "text",
        //   icon: "",
        // },
        // {
        //   label: this.$t("sinoma.btn.execute"),
        //   emit: "grid-execute",
        //   remark: "execute",
        //   type: "text",
        //   icon: "",
        // },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },
      ],



      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label:this.$t('cip.dc.DTManagement.field.TaskName'),

            prop: "taskName",
            cell: true,
          },
          {
            label:this.$t('cip.dc.DTRecord.field.ThemeDomain'),
            prop: "subjectPname",
            cell: true,
          },
           {
            label:this.$t('cip.dc.DTRecord.field.theme'),

            prop: "subjectName",
            cell: true,
          },
          {
             label:this.$t('cip.dc.QTemplate.field.DataObject'),

            prop: "dataObjectName",
            cell: true,
          },
          {
             label:this.$t('cip.dc.DTRecord.field.resultsOfEnforcement'),
            prop: "taskResult",
            cell: true,
          },
          {
             label:this.$t('cip.dc.DTRecord.field.TriggerType'),
            prop: "triggerType",
            cell: true,
            slot: true,
          },
          {
             label:this.$t('cip.dc.DTRecord.field.DeterminationMethodName'),

            prop: "judgeName",
            cell: true,
            slot:true,
          },
          {
             label:this.$t('cip.dc.DTRecord.field.executionTime'),
            prop: "createTime",
            cell: true,
          },
        ],
      },
    };
  },

  methods: {
    //获取列表
    getList(parms) {
      checkTaskRecordList(parms).then((res) => {
        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize
      });
    },
    onLoad(){
      this.getList(this.listParms)
    },
    czFn(){
      this.listParms.pageNum = 1
      this.listParms.pageSize = 10
      this.listParms.taskName = ""
      this.getList(this.listParms)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.listParms.pageSize = e.pageSize
      this.getList(this.listParms)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.listParms.pageNum = e.currentPage
      this.getList(this.listParms)
    },

    ToJudge(e){
      console.log(e);
      // judgeId
      this.$router.push({
        path:"/Quality/judgeTaskAdd",
        query: {
            type: "seeResult",
            id:e.judgeRecordId
          },
      })
    },


    //查看
    toSee(item) {
      this.$router.push({
        path: "/Quality/taskRecordDetailSee",
        query: {
          type: "see",
          id: item.id,
        },
      });
    },
    //执行和删除

    toExecute(item) {
      this.dialogVisible = true;
      this.dialogtext = this.$t('cip.dc.DComission.msg1')
      this.ExecuteId = item.id;
      this.btnType = 'execute'
    },
    SureToExecute() {
      this.dialogVisible = false;
      if(this.btnType == 'execute'){
         ExecuteTask({ id: this.ExecuteId }).then((res) => {
        this.$message({
          message: this.$t('cip.dc.DComission.msg3'),
          type: "success",
        });
      });
      }
      if(this.btnType == 'del'){
        DelTaskRecordDetail({id:this.ExecuteId}).then(res=>{
          this.$message({
          message: this.$t('cip.dc.offlineDComission.delmsg'),
          type: "success",
        });
        this.getList(this.listParms)
         })
      }

    },
    toDel(item){
      this.dialogVisible = true;
      this.dialogtext = this.$t('cip.dc.qualityDComission.msg1')
      this.ExecuteId = item.id;
       this.btnType = 'del'

    }
  },
  mounted() {
    this.getList(this.listParms);
  },
};
</script>
<style  scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
